/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <div>
      <br/>
      <div style={{display:"flex",justifyContent:"space-between",marginLeft:"10px",marginRight:"10px",paddingLeft:"10px",paddingRight:"10px"}}>
        <a href={"/about-us"}>About</a>
        <div>|</div>
        <a href={"/terms-of-use"}>Terms of Use</a>
        <div>|</div>
        <a href={"/privacy-policy"}>Privacy Policy</a>
        <div>|</div>
        
        <a href={"/cancellation-and-refund"}>Cancellation/Refunds</a>
        <div>|</div>
        <a href={"/contact-us"}>Contact Us</a>
      </div>
      <footer className={footerClasses}>
        

        <div className={classes.container}>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://ai.incubig.org"
                  className={classes.block}
                  target="_blank"
                >
                  Connecting, promoting and  accelerating innovation.
                </a>
              </ListItem>
              
              
              
            </List>
          </div>
          <div className={classes.right}>
            &copy; {1900 + new Date().getYear()}{" "}
              Incubig
            {" "}
          </div>
        </div>
        
      </footer>
    </div>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
