import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product

import Landing from "views/LandingPage/dashboard";
import About from "views/LandingPage/about"
import Privacy from "views/LandingPage/privacyPolicy";
import Terms from "views/LandingPage/terms";
import Refund from "views/LandingPage/refund";
import Pricing from "views/LandingPage/pricing";
import ContactUs from "views/LandingPage/contact";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/privacy-policy" component={Privacy} />
      <Route exact path="/terms-of-use" component={Terms} />
      <Route exact path="/cancellation-and-refund" component={Refund} />
      <Route exact path="/about-us" component={About} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/pricing" component={Pricing} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
